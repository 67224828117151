@import "@ngxhq/theme-ngxhq/scss/_core";
$table-border-color: #c8c8c8;

.p-datatable-caption {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
        margin-left: 5px;
    }
}


.p-datatable-table {
    width: 100%;
    table-layout: fixed;

    .p-datatable-thead {
        th {
            position: relative;

            p-sorticon {
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
            }

            .p-calendar {
                .p-highlight {
                    background: $hq-color-DS-100;
                    color: $hq-color-DS-reverse-100;
                }
            }
        }
    }

    .p-datatable-tbody {
        td {
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
}

.p-datatable-wrapper {
    border-top: 1px solid $table-border-color;
    border-left: 1px solid $table-border-color;
    border-right: 1px solid $table-border-color;
    overflow: visible !important;
}

.p-multiselect,
.p-dropdown,
.p-autocomplete,
.p-inputnumber {
    min-width: 100%;
    max-width: 100%;

    .p-autocomplete-input {
        padding: .429rem;
    }
}

.p-multiselect-panel {
    min-width: calc(100% + 80px) !important;
}

.p-multiselect-filter,
.p-dropdown-filter {
    border-radius: 3px;
    border: 1px solid #036;
}

.p-element.p-selectable-row.ng-star-inserted td {
    overflow: visible;
}

@media screen and (max-width: 960px) {
    tbody.p-element.p-datatable-tbody>tr>td {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        border: none;

        span {
            display: block;
        }

        &:last-child {
            border-bottom: 1px solid $table-border-color;
            display: flex;
            justify-content: space-between;
        }
    }

    .p-element.p-selectable-row.ng-star-inserted>td:last-child {
        padding: 0;
    }

    .p-datatable-thead {
        display: none;
    }
}