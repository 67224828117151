@import "@ngxhq/theme-ngxhq/scss/_core";
@import "../assets/appicons/appicons";
@import "./partials/table";
@import "./partials/confirm-dialog";
@import "./partials/autocomplete";

::-webkit-scrollbar {
    width: 12px !important;
    border-radius: 10px;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-thumb {
    background: linear-gradient(90deg, #5f99c6, #66a2d1, #5f99c6);
    border-radius: 10px;
}

.fullscreen-background {
    min-height: 100vh;
    background-image: radial-gradient(circle at 17% 77%, rgba(17, 17, 17, .04) 0, rgba(17, 17, 17, .04) 50%, rgba(197, 197, 197, .04) 50%, rgba(197, 197, 197, .04) 100%), radial-gradient(circle at 26% 17%, rgba(64, 64, 64, .04) 0, rgba(64, 64, 64, .04) 50%, rgba(244, 244, 244, .04) 50%, rgba(244, 244, 244, .04) 100%), radial-gradient(circle at 44% 60%, rgba(177, 177, 177, .04) 0, rgba(177, 177, 177, .04) 50%, rgba(187, 187, 187, .04) 50%, rgba(187, 187, 187, .04) 100%), linear-gradient(19deg, rgba(0, 51, 102, .95), rgba(27, 117, 187, .85));
    background-size: cover;
    transition: background-image 25s ease-in .2s;

    .hq-header {
        background-color: transparent !important;
    }
}

/* Fix pour le icon filter slash */
.pi-filter-slash {
    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
        content: "";
        position: absolute;
        background-image: url('../assets/appicons/filter-off-solid.svg');
        display: flex;
        background-size: contain;
        background-repeat: no-repeat;
        width: 50%;
        height: 60%;
    }
}

/*Style pour modifier l'italic dans les placeholder des champs */
::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-style: normal !important;
}

::-moz-placeholder {
    /* Firefox 19+ */
    font-style: normal !important;
}

:-ms-input-placeholder {
    /* IE 10+ */
    font-style: normal !important;
}

:-moz-placeholder {
    /* Firefox 18- */
    font-style: normal !important;
}


// styles de base pour les cartes
.call-to-action {

    .p-card {
        display: flex;
        align-items: center;
        transition: transform 0.1s;
        height: 100%;

        &:hover {
            cursor: pointer;
            transform: translate(0, 5px);
        }

        .p-card-header {
            padding: 1rem;
            font-size: 45px;
        }

        .p-card-title {
            position: relative;
            top: -5px;
            margin-bottom: 0;
        }

        .p-card-description {
            margin: 0;
        }
    }
}

.p-dialog-mask {
    background-color: rgba(27, 118, 187, 0.4) !important;
    backdrop-filter: blur(5px) !important;
}

.p-datepicker-trigger {
    height: 40px !important;
}

.p-datepicker {
    .p-datepicker-group-container {
        .p-datepicker-group {
            .p-datepicker-header {
                .p-datepicker-title {
                    .p-datepicker-month {
                        text-transform: capitalize;
                    }
                }
            }
        }
    }
}

.p-paginator-pages {
    .p-paginator-page {
        width: 2.25rem !important;
    }
}

.hq-ui-menuitem-text {
    line-height: 16px;
}

.p-dropdown-clear-icon {
    border: 1px solid #036 !important;
    border-radius: 50% !important;
    font-size: 1em !important;
    font-weight: 600 !important;
    padding: 6px !important;
    transform: scale(.7) !important;
}
