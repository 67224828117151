.p-confirm-dialog {
    .p-dialog-footer {
        justify-content: flex-end !important;

        .p-button {
            &.p-button-danger {
                background: $hq-color-DS-100;
                border: 2px solid $hq-color-DS-100;
                box-shadow: 0 0 1px 1px $hq-color-DS-100;
            }
        }
    }
}
