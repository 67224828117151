.p-autocomplete {
    width: 100%;

    .p-autocomplete-input {
        height: 39.52px;
        padding: .429rem
    }

    .p-icon-wrapper {
        svg {
            speak: none;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            display: inline-block;
            font-family: primeicons;
            font-style: normal;
            font-feature-settings: normal;
            font-variant: normal;
            font-weight: 400;
            line-height: 1;
            text-transform: none;
            animation: fa-spin 2s linear infinite;
        }

    }
}
